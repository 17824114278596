@import '../../assets/styles/variables';

.Question {
  width: 100%;

  .questionContainer {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 42px;
    padding-right: 42px;
    padding-bottom: 16px;
    margin-bottom: 28px;
    background-color: white;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
    border-radius: 60px;

    .responseCount {
      display: inline-block;
      margin-left: auto;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      text-align: right;
      color: $text;
    }

    p {
      margin-bottom: 20px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 35px;
      color: $text;
    }
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
}
