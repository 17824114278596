@import '../../assets/styles/variables';

.Textarea {
  width: 100%;
  height: 145px;
  padding: 20px;
  padding-left: 25px;
  padding-right: 75px;
  font-size: 24px;
  line-height: 100%;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
  border-radius: 20px;
  resize: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    line-height: 86px;
    text-align: center;
    background-color: #ffffff;
    color: $primary;
    font-size: 24px;
    font-weight: bold;
    cursor: auto;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
}
