@import '../../../../assets//styles/variables';

.Stat {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 300px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: $lightPink;
  border-radius: 16px;

  p,
  h4 {
    margin: 0;
  }

  .title {
    font-size: 20px;
  }

  .value {
    font-size: 64px;
    font-weight: bold;
  }

  .description {
    font-weight: 500;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-weight: 500;
    }
  }
}
