.dialog {
  max-width: 746px;
}

.ImportFileModal {
  position: relative;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px 68px;
    
    .title {
      margin: 0 0 32px;
      color: #545454;
    }

    .buttonsContainer {
      width: 100%;
      display: flex;
      gap: 20px;

      .button {
        flex-grow: 1;
      }
    }
  }
}
