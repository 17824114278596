@import '../../assets/styles/variables';

.RequestFolderAccessPage {
  min-height: 100vh;
  padding: 60px 80px;
  background-color: #f9f9f9;

  header {
    h1 {
      margin: 0 0 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: $text;
    }
  }

  h2 {
    margin: 0 0 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 133%;
    color: $text;
  }

  p {
    margin: 0 0 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: $text;
  }

  .form {
    margin-bottom: 20px;

    .row {
      display: flex;

      &.emailRow {
        margin-bottom: 45px;
      }

      .col {
        flex: 1;

        .title {
          h3 {
            margin-bottom: 4px;
            display: flex;
            gap: 10px;
            font-size: 24px;
            line-height: 100%;
            color: $text;
          }

          hr {
            margin: 0 0 16px;
          }
        }
      }

      .error {
        padding-left: 5px;
        color: $red;
      }
    }

    .emailContainer {
      width: 40%;
    }

    .messageContainer {
      width: 80%;
      position: relative;

      textarea {
        height: 200px;
      }
    }
  }
}
