.ActionsMenu {
  position: relative;

  button {
    background-color: transparent;
    border: none;
  }

  .menu {
    width: 360px;
    padding: 14px 28px;
    display: none;
    position: absolute;
    bottom: -132px;
    right: 100%;
    z-index: 1;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #ffffff;

    &.isVisible {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 6px;
      list-style: none;

      li {
        button {
          display: flex;
          align-items: center;
          font-family: 'Inter';
          font-size: 20px;
          line-height: 125%;

          img {
            margin-right: 8px;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
