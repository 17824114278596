@import '../../assets/styles/mixins';

.dialog {
  max-width: 672px;
  max-height: 264px;
}

.UpdateMeetingTitleModal {
  padding: 32px 24px 24px;
  position: relative;
  border-radius: 16px;
  background-color: #f9f9f9;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;

    @include onMobile {
      row-gap: 24px;
    }

    .title {
      display: none;

      @include onMobile {
        display: flex;
        margin: 0 auto 0 0;
        font-size: 20px;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      column-gap: 48px;

      @include onMobile {
        column-gap: 24px;
      }
    }
  }
}
