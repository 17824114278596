@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.SearchBar {
  position: relative;
  height: 46px;

  &.active input {
    width: 280px;

    &::placeholder {
      opacity: 1;
    }

    @include onMobile {
      &:focus::placeholder {
        visibility: hidden;
      }
    }
  }

  input {
    height: 46px;
    width: 46px;
    padding: 6px 12px;
    border: 2px solid $primary;
    border-radius: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 112%;
    color: $text;
    outline: none;
    transition: width 0.3s;

    @include onMobile {
      border: none;
    }

    &::placeholder {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 46px;
    width: 46px;
    transition: transform 0.3s;

    img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}