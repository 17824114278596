@import '../../assets/styles/variables';

.dialog {
  max-width: 746px;
}

.SelectShareOptionModal {
  position: relative;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 88px 80px 60px;

    h3 {
      margin-bottom: 32px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      text-align: center;
      color: $text;
    }

    .buttons {
      display: flex;
      column-gap: 40px;

      button {
        padding: 25px 50px;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #ffffff;
      }
    }
  }
}
