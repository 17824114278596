@import '../../assets/styles/variables';

.dialog {
  max-width: 600px;
}

.MeetingDetailsModal {
  padding: 40px 106px 48px 48px;
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 100%;
      color: $text;
    }
  }
}
