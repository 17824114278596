.sortableTh {
  padding: 16px 56px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 112%;
  text-align: center;
  cursor: pointer;

  &:not(.sortable) {
    cursor: default;
  }

  .sortOrder {
    .chevron {
      width: 15px;
      margin-left: 2px;
    }

    &.asc {
      .chevron {
        transform: rotate(180deg);
      }
    }

    /*    &.desc {
      .chevron {
        position: relative;
        top: 0;
      }
    } */
  }
}
