@import '../../../assets/styles/variables';

.UsersTable {
  width: 100%;

  thead {
    height: 50px;

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      th {
        padding: 0;
        padding-right: 10px;
        font-size: 16px;

        &:first-child {
          padding-left: 36px;
        }

        &:last-child {
          padding-right: 36px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 80px;
      td {
        padding-right: 10px;
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 400;
        line-height: 125%;
        color: $text;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &:first-child {
          padding-left: 36px;
        }

        &:last-child {
          padding-right: 36px;
        }
      }
    }
  }
}
