@import '../../assets/styles/variables';

.Switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  // width: 51px;
  height: 31px;
  padding-right: 51px;
  cursor: pointer;

  &.withTooltip {
    .label {
      padding-left: 5px;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $pink;
    }

    &:checked + .slider:before {
      transform: translateX(calc(var(--width) - 27px - 5px));
    }
  }

  .onLabel {
    position: absolute;
    color: white;
    right: calc(var(--width) - 27px - 5px);
  }

  .label {
    padding-right: 15px;
    font-weight: bold;
  }

  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 51px;
    border-radius: 34px;
    background: rgba(120, 120, 128, 0.32);
    transition: all 0.2s ease;

    &::before {
      content: '';
      width: 27px;
      height: 27px;
      position: absolute;
      left: 0;
      transform: translateX(5px);
      top: calc(50% - 27px / 2);
      border-radius: 50%;
      background-color: #ffffff;
      border: 0.5px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
        0px 3px 1px rgba(0, 0, 0, 0.06);
      transition: all 0.2s ease;
    }
  }
}
