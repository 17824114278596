@import '../../assets/styles/variables';

.dialog {
  max-width: 1100px;
}

.MeetingNotesModal {
  position: relative;
  border-radius: 25px;
  height: 674px;

  .header {
    padding-top: 24px;
    padding-left: 32px;
    margin-bottom: 24px;

    .closeIcon {
      position: absolute;
      top: 35px;
      right: 35px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../assets/images/view-meeting/close.svg);
      background-size: cover;
      cursor: pointer;
    }

    h1 {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: $text;
    }
  }
  .editorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 32px;
    padding-right: 36px;

    .editorWrapper {
      width: 100%;

      .toolbarIcon {
        height: unset;
        margin: 0;
        padding: 0;
        border: none;
      }

      .editor {
        height: 376px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #f5f6f7;
        box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
        border-radius: 20px;

        li {
          span {
            line-height: 100%;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding-right: 67px;
    padding-top: 74px;

    .saveButton {
      width: 85px;
      height: 85px;
      background-color: $pink;
      box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
      border-radius: 50%;
      border: none;
    }
  }
}
