@import '../../assets/styles/variables';

.SignUpPage {
  .signIn {
    margin-bottom: 64px;
    align-self: flex-end;

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: $pink;
    }
  }

  h1 {
    margin-bottom: 56px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 70px;
    line-height: 100%;
    color: $text;
  }

  form {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    .row {
      height: 46px;
      display: flex;
      align-items: center;
      column-gap: 16px;
    }
  }

  .termsPrivacyMessage {
    max-width: 336px;
    margin-bottom: 0;
    align-self: flex-start;
    line-height: 112%;
    color: $text;

    a {
      color: $pink;
      text-decoration: none;
    }
  }
}
