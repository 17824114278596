@import '../../../../assets//styles/variables';

.Tab {
  // opacity: 0.1;
  position: relative;
  z-index: 1;
  // left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  background-color: #e9e9ed;
  border: none;
  border-top: 1px solid gray;

  &:first-child {
    &::after {
      display: none;
    }
  }

  &:last-child {
    &::before {
      top: -2px;
      height: 52px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: -40px;
    width: 100px;
    height: 51px;
    background-color: #e9e9ed;
    border-top-left-radius: 10px;
    transform: skew(-28deg);
    border-left: 1px solid gray;
    overflow: hidden;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -40px;
    width: 100px;
    height: 51px;
    background-color: #e9e9ed;
    border-top-right-radius: 10px;
    transform: skew(28deg);
    border-right: 1px solid gray;
    overflow: hidden;
  }

  &:focus {
    outline: none;
    color: $pink;
  }

  &:hover {
    z-index: 2000;
    background-color: $gray;
    color: white;

    &::before {
      background-color: $gray;
    }

    &::after {
      background-color: $gray;
    }
  }

  &.active {
    opacity: 1;
    background-color: $pink;
    z-index: 1111;

    &::before {
      background-color: $pink;
    }

    &::after {
      background-color: $pink;
    }

    .title {
      color: white;
    }
  }

  .fakeBorder {
    position: absolute;
    z-index: 111111;
    top: -1px;
    right: -21.5px;
    width: 220px;
    height: 1px;
    background-color: gray;
  }

  .title {
    position: relative;
    display: inline-block;
    // transform: skew(-28deg);
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
}
