@import '../../../assets/styles/variables';

.ReportHeader {
  padding: 20px 40px;
  border-radius: 64px;

  .container {
    display: flex;
    justify-content: space-between;

    h1 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: $primary;
    }

    .title {
      margin-bottom: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 133%;
      text-transform: uppercase;
      color: $primary;
    }
  }

  .reportInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    .infoContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .titleContainer {
      position: relative;
      left: -10px;
      display: flex;
      align-items: center;
      height: 32px;
    }

    .contentTitle,
    .reportDate {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 133%;
      color: $text;
    }

    p {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      color: $text;
    }

    .completionRate {
      margin-bottom: 0;
    }

    .buttonContainer {
      display: flex;
      padding-top: 30px;
    }
  }
}
