@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.MeetingsPage {
  min-height: 100vh;

  .breadcrumbsContainer {
    padding-bottom: 32px;

    @include onMobile {
      padding-bottom: 16px;
    }

    .title {
      margin: 0;
      font-weight: 600;
      font-size: 26px;
      line-height: 125%;
      color: $text;

      @include onMobile {
        display: none;
      }
    }
  }

  .container {
    min-height: calc(100vh - 204px);
    padding: 16px 72px;

    @include onMobile {
      min-height: calc(100vh - 244px);
      padding: 16px 32px;
    }

    button {
      padding: 0;
      background-color: transparent;
      border: none;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .foldersContainer {
      display: flex;
      gap: 20px;
      margin-bottom: 40px;

      @include onMobile {
        flex-direction: column;
        gap: 0;
      }
    }

    .tableContainer {
      display: flex;
      justify-content: center;

      .table {
        width: 100%;
        table-layout: fixed;

        tr {
          position: relative;
        }

        thead tr,
        tbody tr:not(:last-of-type) {
          &::after {
            content: '';
            height: 1px;
            display: block;
            position: absolute;
            left: 8px;
            right: 8px;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.05);

            @include onMobile {
              display: none;
            }
          }
        }

        thead {
          height: 50px;

          @include onMobile {
            display: none;
          }

          tr {
            th {
              padding: 0 32px 12px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 112%;
              color: $text;
              cursor: pointer;

              &.sorted {
                font-weight: bold;
              }

              .sortOrder {
                .chevron {
                  width: 15px;
                  margin-left: 2px;
                }

                &.asc {
                  .chevron {
                    position: relative;
                    top: -2px;
                    transform: rotate(180deg);
                  }
                }

                &.desc {
                  .chevron {
                    position: relative;
                    top: 0px;
                  }
                }
              }
            }

            .searchBarContainer {
              padding-right: 20px;
              display: flex;
              flex-direction: row-reverse;
            }
          }
        }

        tbody {
          tr {
            td {
              min-height: 120px;
              padding: 8px 32px 8px;
              font-family: 'Inter';
              font-size: 20px;
              line-height: 125%;
              color: $text;

              @include onMobile {
                height: auto;
              }

              &.capitalize {
                text-transform: capitalize;
              }

              &.title {
                height: 100%;
                padding-left: 0;
                margin-left: 32px;
                //  display: flex;
                //   align-items: center;
                //  justify-content: center;

                @include onMobile {
                  min-height: 70px;
                  margin: 0;
                  padding-right: 0;
                  display: flex;
                  justify-content: space-between;
                }

                a {
                  text-decoration: none;
                  color: $text;

                  @include onMobile {
                    font-weight: 600;
                    font-size: 18px;
                  }

                  &:hover {
                    text-decoration-line: underline;
                    color: $pink;

                    @include onMobile {
                      text-decoration-line: none;
                      color: unset;
                    }
                  }
                }

                .titleText {
                  @include onMobile {
                    font-weight: 600;
                    font-size: 16px;
                  }
                }

                .videoIcon {
                  display: none;

                  @include onMobile {
                    display: table-cell;
                    height: 36px;
                    width: 36px;
                    margin-right: 8px;
                  }
                }

                .left {
                  @include onMobile {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .titleContainer {
                      width: 100%;
                      padding-right: 10px;
                    }
                  }
                }

                .right {
                  display: flex;
                  gap: 4px;

                  button {
                    display: none;

                    @include onMobile {
                      display: flex;
                      margin-left: auto;
                      background: transparent;
                      border: none;

                      img {
                        width: 36px;
                        height: 36px;
                      }
                    }
                  }
                }

                .publishedDate {
                  display: none;

                  @include onMobile {
                    display: block;
                    font-size: 14px;
                  }
                }
              }

              &.duration {
                text-align: center;
                min-width: 80px;

                @include onMobile {
                  display: none;
                }
              }

              &.owner {
                @include onMobile {
                  display: none;
                }
              }

              &.status {
                @include onMobile {
                  display: none;
                }
              }

              &.comment {
                max-width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include onMobile {
                  display: none;
                }
              }

              &.buttons {
                padding-right: 20px;
                display: flex;
                flex-direction: row-reverse;

                @include onMobile {
                  display: none;
                }

                .buttonsContainer {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                  gap: 24px;
                }
              }
            }

            &.clickable {
              cursor: pointer;

              &:hover::before {
                content: '';
                height: 100px;
                width: 100%;
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: #f9f9f9;
                z-index: -1;
                border-radius: 40px;

                @include onMobile {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .noMeetings {
      margin: 0;
      position: absolute;
      top: 65%;
      right: 50%;
      transform: translateX(50%);
      text-align: center;
      font-weight: 400;
      font-size: 42px;
      line-height: 114%;
      color: $text;

      @include onMobile {
        position: unset;
        top: unset;
        right: unset;
        transform: unset;
        margin: 32px 0;
        font-size: 32px;
      }
    }

    .paginationContainer {
      display: flex;
      justify-content: flex-end;

      @include onMobile {
        justify-content: center;
      }

      .buttonsList {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;

        &.disabled {
          opacity: 0.4;

          a {
            cursor: not-allowed;
          }
        }

        &.active {
          a {
            width: 24px;
            text-align: center;
            font-family: 'Inter';
            line-height: 125%;
            border-radius: 4px;
            background-color: $red;
            color: #ffffff;
          }
        }

        a {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          text-decoration: none;
          color: $text;
        }
      }
    }

    .uploadButton {
      display: none;

      @include onMobile {
        display: block;
        position: fixed;
        bottom: 16px;
        right: 16px;
        background: #000000;
        border-radius: 50%;
      }
    }
  }
}
