@import '../../assets/styles/variables';

.NotAllowedToDeleteSectionMessage {
  position: absolute;
  z-index: 2;
  left: calc(50%);
  top: calc(50%);
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px 72px 44px;

    p {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 133%;
      color: $text;
    }
  }
}
