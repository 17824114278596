.FontSize {
  display: flex;
  align-items: center;
  gap: 18px;
  height: 72px;
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50px;

  .fontSize {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 108px;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    &:focus {
      outline: none;
    }
  }

  button {
    // padding: 0;
    background-color: transparent;
    border: none;
  }
}
