@import '../../../assets/styles/variables';

.PlayerButtons {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  &.chrome {
    ul {
      li {
        .recordControl {
          position: relative;
          top: -4px;
        }
      }
    }
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 38px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 0;
      cursor: pointer;

      button {
        padding: 0;
        border: none;
        background-color: transparent;
      }

      .recordControl {
        display: flex;
        align-self: center;
        align-items: center;
        gap: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 22px;
        line-height: 109%;
        color: #ffffff;
        border: 2px solid white;
        border-radius: 5px;

        .stopButton {
          width: 18px;
          height: 18px;
        }
      }

      .recordButton {
        width: 20px;
        height: 20px;
        background-color: $red;
        border-radius: 50%;
      }

      .stopButton {
        width: 22px;
        height: 22px;
        background-color: white;
      }
    }
  }
}
