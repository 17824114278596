@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.Breadcrumbs {
  display: flex;
  align-items: center;
  column-gap: 20px;

  .link {
    position: relative;
    color: $text;
    text-decoration: none;

    @include onMobile {
      color: #666666;
      font-weight: 600;

      &.linkWithPrev::before {
        content: "< ";
      }
    }

    &:not(:last-child)::after {
      content: ">";
      position: absolute;
      right: -15px;
    }
  }

  .backButton {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background: transparent;
  }
}