.UserSearchSuggestion {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 15px;
  width: 90%;
  height: 85px;
  padding: 15px;
  background-color: #f9dbe3;
  cursor: pointer;

  .avatarContainer {
    img {
      width: 55px;
      height: 55px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .details {
    .title {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 100%;
      font-weight: bold;
    }
  }
}
