@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.Subfolder {
  height: 100px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  border: 2px solid #e9e9e9;
  border-radius: 20px;
  cursor: pointer;

  @include onMobile {
    height: unset;
    border: none;
    padding: 8px 0;
  }

  &:hover {
    background: #f9f9f9;

    @include onMobile {
      background: unset;
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-size: 20px;
    line-height: 125%;
    color: rgba(0, 0, 0, 0.8);

    .newVideosAdded {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      color: $yellow;
    }

    img {
      margin-right: 16px;

      @include onMobile {
        width: 36px;
        height: 36px;
      }
    }

    .publishedDate {
      display: none;

      @include onMobile {
        display: block;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .actionsContainer {
    display: flex;
    margin-left: auto;

    @include onMobile {
      display: none;
    }
  }
}
