.NoTitleWarningPopup {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .popup {
    position: absolute;
    width: 417px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    border-radius: 25px;

    .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    span {
      display: block;
      max-width: 300px;
    }

    p {
      width: 300px;
      margin: 0;
      font-weight: normal;
      text-align: center;
    }
  }
}
