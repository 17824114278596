@import '../../../assets/styles/variables';

.VideoApproval {
  height: 100px;
  padding-right: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $primary;
  opacity: 80%;

  .buttons {
    height: 50%;
    display: flex;
    column-gap: 60px;

    button {
      width: 200px;
      border-radius: 34px;
    }
  }
}
