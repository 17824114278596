@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.SignInPage {
  .logoContainer {
    display: none;
    margin-bottom: 100px;

    @include onMobile {
      aspect-ratio: 341 / 191;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .signUp {
    margin-bottom: 64px;
    align-self: flex-end;

    @include onMobile {
      display: none;
    }

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: $pink;
    }
  }

  h1 {
    margin: 64px 0 56px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 70px;
    line-height: 100%;
    color: $text;

    @include onMobile {
      margin: 0 0 40px;
      font-size: 48px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    .row {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include onMobile {
        flex-direction: column;
        gap: 16px;
        height: auto;
        justify-content: center;

        button {
          width: 100%;
        }
      }

      .forgotPassword {
        margin: -21px 0 0;
        align-self: flex-start;
        line-height: 112%;
        color: rgba(0, 0, 0, 0.5);

        @include onMobile {
          width: 100%;
          margin: 0;
          order: 1;
          text-align: center;
        }

        a {
          color: $pink;
          text-decoration: none;

          @include onMobile {
            font-size: 18px;
            font-weight: 500;
            color: $text;
          }
        }
      }
    }
  }
}
