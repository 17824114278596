@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.EditableTitle {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  span {
    padding-right: 10px;
    overflow: hidden;
    max-width: 280px;

    @include onMobile {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .editButton {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: -6px;
    transform: translateX(100%);
    background-image: (url('../../../assets/images/my-meetings/edit.svg'));
    background-repeat: no-repeat;
    background-size: contain;
    background-color: unset;
    border: none;
  }

  input {
    width: 100%;
    margin-bottom: -2px;
    padding: 0;
    outline: none;
    border: none;
    border-bottom: 2px dashed $primary;
    background-color: transparent;
  }
}
