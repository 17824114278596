@import '../../assets/styles/variables';

.dialog {
  max-width: 1014px;
}

.CreateQuestionModal {
  padding: 72px 68px 36px;
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 22px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      text-align: center;
      color: $text;
    }

    .form {
      width: 772px;

      .questionContainer {
        height: 145px;
        position: relative;

        textarea {
          width: 100%;
          height: 100%;
          padding: 16px 48px;
          font-size: 18px;
          border: none;
          background-color: #ffffff;
          box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
          border-radius: 20px;
          resize: none;

          &::placeholder {
            font-size: 24px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.3);
          }

          &:focus {
            outline: none;

            &::placeholder {
              color: transparent;
            }
          }
        }
      }

      .optionsContainer {
        padding-top: 20px;
        padding-left: 16px;
        display: flex;
        align-self: flex-start;
      }
    }

    .footer {
      width: 772px;
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
