@import '../../assets/styles/variables';

.dialog {
  max-width: 1120px;
}

.RequestReviewModal {
  position: relative;
  padding: 36px 60px 24px;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  header {
    margin-bottom: 15px;

    h1 {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: $text;
    }
  }

  .form {
    margin-bottom: 32px;

    .row {
      display: flex;

      &.inviteRow {
        margin-bottom: 45px;
        gap: 40px;
      }

      .col {
        flex: 1;

        .title {
          h2 {
            margin-bottom: 4px;
            display: flex;
            gap: 10px;
            font-size: 24px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.4);

            .infoIconContainer {
              font-weight: normal;
              font-size: unset;
            }
          }

          hr {
            margin: 0;
            margin-bottom: 12px;
          }
        }
      }

      .error {
        padding-left: 5px;
        color: $red;
      }
    }

    .messageContainer {
      position: relative;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
  }
}
