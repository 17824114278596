@import '../../../assets/styles/variables';

.Answer {
  display: flex;
  align-items: center;

  .userpic {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    width: 100%;
    min-height: 81px;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 24px;
    background-color: white;
    border-radius: 60px;
    box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);

    h1 {
      margin-bottom: 8px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: $text;
    }

    h2 {
      margin-bottom: 4px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $text;
    }
  }

  p {
    margin: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: $text;
  }
}
