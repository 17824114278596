@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.MeetingVideoPlayer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: $primary;

  header {
    display: flex;
    column-gap: 20px;
    position: absolute;
    top: 16px;
    left: 18px;
    z-index: 2;

    .backButton {
      display: flex;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    h1 {
      margin-bottom: 0;
      color: #ffffff;
      font-size: 24px;
    }
  }

  .sectionsVisibilityButton {
    position: absolute;
    bottom: 25px;
    padding: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: white;
    background-color: transparent;
    border: none;

    @include onMobile {
      display: none;
    }
  }

  video {
    height: calc(100vh - 150px - 123px - 60px - 100px);
    width: min-content;
    display: flex;
    margin: 0 auto 20px;
    border-radius: 22px;

    @include onMobile {
      height: calc(100vh - 112px - 111px - 60px - 20px - 100px);
      width: 100%;
    }
  }

  .waveFormContainer {
    position: absolute;
    left: 20%;
    right: 20%;
    top: 0;
    bottom: 123px;
    background-color: $primary;
    z-index: 1;
  }

  .controlsContainer {
    display: flex;
    justify-content: center;
    height: 100px;

    .controls {
      display: flex;
      align-items: center;
      gap: 25px;

      div {
        cursor: pointer;
      }

      .playButtonContainer {
        transform: rotate(-90deg);
        height: 22px;

        .playButton {
          position: relative;
          top: 10px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid white;
          transform: scaleY(1.66);
        }
      }

      .pauseButton {
        width: 20px;
        display: flex;
        gap: 10px;
        height: 22px;

        div {
          width: 7px;
          height: 100%;
          background-color: white;
        }
      }

      .stopButton {
        width: 22px;
        height: 22px;
        background-color: white;
      }

      .rewindButton {
        position: relative;
        left: 4px;
        transform: rotate(-180deg);
      }
    }
  }
}