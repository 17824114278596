@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.UnderlinedInput {
  flex: 1 1;
  position: relative;

  input {
    width: 100%;
    height: 46px;
    padding-bottom: 12px;
    border: none;
    border-bottom: 1px solid $primary;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    color: $text;
    transition: border-bottom-color 0.2s;

    @include onMobile {
      height: 40px;
      padding-bottom: 8px;
      font-size: 24px;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #ffffff inset;
    }
    
    &:focus {
      outline: none;
    }

    &:-webkit-autofill ~ label,
    &:focus ~ label,
    &.active ~ label,
    &.error ~ label {
      font-size: 16px;
      line-height: 112%;
      transform: translateY(-36px);
      color: rgba(0, 0, 0, 0.5);
    }

    &.error ~ label {
      color: $yellow;
    }

    &.error {
      border-bottom-color: $yellow;
    }

    &:focus ~ .passwordStrength {
      opacity: 100%;
    }
  }

  label {
    position: absolute;
    left: 2px;
    bottom: 10px;
    pointer-events: none;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.2);
    transition: all 0.2s;

    @include onMobile {
      font-size: 24px;
    }
  }

  .passwordStrength {
    display: flex;
    column-gap: 8px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 0.2s;

    span {
      line-height: 112%;
      color: #d9d9d9;

      &.error {
        color: $yellow;
      }

      &.success {
        color: #00d32f;
      }
    }
  }
}
