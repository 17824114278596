@import '../../assets/styles/variables';

.Option {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  padding-left: 28px;
  line-height: 150%;
  color: $text;
  background-image: url("../../assets/images/uncheckedRadio.svg");
  background-repeat: no-repeat;
  background-position: center left 0;
  cursor: pointer;

  &.active {
    background-image: url("../../assets/images/checkedRadio.svg");
  }
}