@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.dialog {
  max-width: 746px;
  max-height: 652px;

  @include onMobile {
    max-width: 100%;
    max-height: 100%;
  }
}

.FileUploadProgressModal {
  padding: 40px 106px 48px 48px;
  position: relative;
  border-radius: 15px;

  @include onMobile {
    padding: 32px 24px;
  }

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;

    @include onMobile {
      top: 14px;
      right: 18px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: $text;

      @include onMobile {
        font-size: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 100%;
      color: $text;

      @include onMobile {
        margin-bottom: 24px;
        font-size: 20px;
      }
    }

    .progressContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      progress {
        accent-color: #f8585c;
      }

      .percentage {
        font-size: 18px;
      }
    }
  }
}
