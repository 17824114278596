@import '../../assets/styles/variables';

.dialog {
  max-width: calc(100vw - 80px);
  padding-right: 0px !important;
}

.contentContainer {
  border-radius: 32px;
  overflow: hidden;
}

.SummaryViewerReportModal {
  padding-right: 0 !important;
}
