@import '../../assets/styles/variables';

.dialog {
  max-width: 1016px;
  max-height: 698px;
}

.PublishMeetingModal {
  padding: 72px 110px 36px 74px;
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  .form {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 20px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      text-align: center;
      color: $text;
    }

    .commentContainer {
      margin-bottom: 42px;
      position: relative;
    }
    
    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 20px;
    }
  }
}
