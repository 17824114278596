@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.sections {
  position: relative;
  width: 100%;
  padding-left: 104px;
  padding-right: 104px;
  background-color: #efefef;

  @include onMobile {
    display: none;
  }

  .switch {
    position: absolute;
    top: calc(50% - 24px);
    left: 24px;
    width: 48px;
    height: 48px;
    background-image: url(../../assets/images/section-menu/left-arrow.png);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;

    &.switchRight {
      left: unset;
      right: 24px;
      transform: scale(-1, -1);
    }
  }

  .sectionsListContainer {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;

    .sectionsList {
      position: relative;
      width: 100%;
      height: 236px;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;
      overflow: hidden;

      .inner {
        position: absolute;
        top: 30px;
        display: flex;
        gap: 16px;
        transition: left 0.2s;

        li {
          padding: 0;
          margin: 0;

          &.sectionItem {
            position: relative;
            width: 184px;
            min-width: 184px;
            height: 134px;
            border-radius: 20px;
            cursor: pointer;

            .title {
              position: absolute;
              left: calc(50% - 68px);
              bottom: -40px;
              display: flex;
              align-items: center;
              width: 136px;
              height: 24px;
              padding-left: 18px;
              padding-right: 18px;
              border-radius: 16px;
              background-color: $primary;
              color: #ffffff;

              span {
                width: 100%;
                text-align: center;
                background-color: transparent;
                border: none;
                line-height: 150%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .sectionContent {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 17px;
            }

            .noteSectionContent {
              background-color: #efede9;
            }

            .audioImageBackground,
            video,
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 17px;
            }

            .audioImageBackground {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &.current {
            border: 3px solid $red;

            .title {
              bottom: -43px;
            }
          }
        }
      }
    }
  }
}
