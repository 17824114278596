 @import '../../../assets/styles/variables';
 
 .VideoProgress {
    position: relative;
    z-index: 2;

    .time {
      position: absolute;
      top: 5px;
      right: 0;
      color: white;
    }

    .progressBar {
      width: 100%;
      height: 5px;
      background-color: white;
      cursor: pointer;
      transition: transform 0.2s;

      .progress {
        position: absolute;
        width: 0;
        height: 5px;
        background-color: $red;

        &::after {
          content: '';
          position: absolute;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: $red;
          right: -10px;
          top: -8px;
          transform: scale(0.2);
          opacity: 0;
          transition: transform 0.2s, opacity 0.2s;
        }
      }

      &.dragging,
      &:hover {
        transform: scaleY(2);
      }

      &.dragging .progress::after,
      &:hover .progress::after {
        opacity: 1;
        transform: scaleY(0.5);
      }
    }
  }