@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Navigation {
  width: 100%;
  padding: 32px 0;
  display: flex;
  justify-content: center;
  position: relative;
  background: #f9f9f9;

  @include onMobile {
    padding: 64px 0 32px;
    background-color: #ffffff;
  }

  .linksContainer {
    display: flex;
    column-gap: 48px;

    a {
      width: 180px;
      position: relative;
      text-align: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 64%;
      text-transform: uppercase;
      color: $gray;
      text-decoration: none;

      @include onMobile {
        width: 146px;
        font-size: 18px;
      }
  
      &.active {
        color: $red;

        &::after {
          content: "";
          height: 2px;
          width: 100%;
          display: block;
          position: absolute;
          bottom: -8px;
          background: $red;
          opacity: 0.8;
        }
      }
    }
  }
}