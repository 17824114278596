.Meeting {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: black;
  overflow-x: hidden;

  .headerContainer {
    width: 100%;

    header {
      width: 100%;
    }
  }
}
