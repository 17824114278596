.ManageUsersMenu {
  position: relative;

  .toggleButton {
    width: 160px;
    padding: 12px 20px;
    position: relative;
    text-transform: capitalize;
    text-align: left;
    border: none;
    font-weight: bold;
    background: transparent;
    font-size: 18px;

    &.active {
      background: #f9f9f9;
    }

    &::after {
      content: "";
      height: 0;
      width: 0;
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 50%;
      margin-left: 10px;
      vertical-align: middle;
      border-top: 5px solid;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  .menu {
    width: 400px;
    padding: 40px 40px 40px 60px;
    position: fixed;
    z-index: 1;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #ffffff;

    .optionsList {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      list-style: none;

      .option {
        display: flex;
        flex-direction: column;

        button {
          padding: 0;
          text-align: start;
          border: none;
          background-color: transparent;
          font-weight: bold;
          font-size: 18px;

          &.active {
            position: relative;

            &::after {
              content: "";
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              left: -40px;
              top: 4px;
              background-image: url('../../../assets/images/my-library/checkmark.svg');
            }
          }
        }
      }
    }
  }
}