@import './assets/styles/variables';
@import './assets/styles/mixins';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

.tooltip {
  font-family: 'Outfit', sans-serif;

  &.show {
    opacity: 1;
  }
}

.tooltip-inner {
  max-width: 360px;
  padding: 24px 16px;
  text-align: left;
  border-radius: 16px;
  font-size: 24px;
  line-height: 121%;
  background-color: #ffffff;
  color: $primary;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  p {
    margin: 0;
  }
}

.tooltip-arrow {
  visibility: hidden;
}
