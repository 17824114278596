@import '../../assets/styles/variables';

.ProfilePage {
  min-height: 100vh;

  .container {
    min-height: calc(100vh - 70px);
    display: flex;

    .sideBar {
      width: 300px;
      padding: 56px 0 0 42px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      background-color: $pink;

      .back {
        width: min-content;
        margin-bottom: 92px;
        display: flex;
        align-items: center;
        column-gap: 16px;
        cursor: pointer;

        span {
          font-family: 'Inter';
          font-weight: 600;
          font-size: 22px;
          line-height: 64%;
          text-transform: capitalize;
          color: #ffffff;
        }
      }

      .listsContainer {
        display: flex;
        flex-direction: column;
        row-gap: 58px;

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          row-gap: 34px;
          list-style: none;

          &:first-of-type {
            position: relative;

            &::after {
              content: '';
              height: 1px;
              width: 80%;
              display: block;
              position: absolute;
              bottom: -29px;
              background-color: rgba(255, 255, 255, 0.4);
            }
          }

          li {
            display: flex;
            align-items: center;
            column-gap: 22px;
            opacity: 0.6;
            cursor: pointer;
            transition: opacity 0.2s;

            img {
              width: 20px;
              height: 20px;
            }

            span {
              font-family: 'Inter';
              font-weight: 500;
              font-size: 16px;
              line-height: 88%;
              color: #ffffff;
            }

            &.active {
              opacity: 1;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .main {
      width: 100%;
      padding: 40px 96px;
      background-color: #f9f9f9;

      .account {
        display: flex;
        justify-content: center;
        column-gap: 32px;

        .userPhoto {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 24px;

          img {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background-color: transparent;
            object-fit: cover;
          }

          ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 8px;
            list-style: none;

            li {
              .removeButton {
                padding: 0;
                font-weight: 500;
                line-height: 150%;
                border: none;
                background-color: transparent;
                color: $text;

                &:disabled {
                  opacity: 0.6;
                  cursor: not-allowed;
                }
              }
            }
          }
        }

        h1 {
          margin-bottom: 20px;
          font-weight: 700;
          font-size: 32px;
          line-height: 75%;
          color: $text;
        }

        form {
          max-width: 600px;
          display: flex;
          flex-direction: column;
          row-gap: 24px;

          .row {
            display: flex;
            align-items: center;
            column-gap: 19px;
          }

          button {
            align-self: flex-end;
          }
        }
      }

      .notificationPreferences {
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 75%;
          color: $text;
        }

        .list {
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          .hint {
            margin-bottom: 16px;
            text-align: right;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            color: $text;
          }

          .item {
            padding: 28px 40px;
            display: flex;
            column-gap: 40px;
            justify-content: space-between;
            align-items: center;
            border-radius: 60px;
            background-color: #ffffff;
            box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);

            p {
              margin: 0;
              font-size: 24px;
              line-height: 100%;
              color: $text;
            }
          }
        }
      }
    }
  }
}
