@import '../../assets/styles/variables';

.dialog {
  max-width: 1016px;
  max-height: 698px;
}

.AssignSectionModal {
  padding: 72px 110px 36px 74px;
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  .successOverlay {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;

    .popup {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 430px;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-right: 40px;
      padding-left: 40px;
      font-size: 20px;
      background-color: white;
      border-radius: 15px;

      span {
        text-align: center;
      }

      .closeIcon {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 20px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      text-align: center;
      color: $text;
    }

    input {
      margin-bottom: 24px;
    }

    .messageContainer {
      margin-bottom: 42px;
      position: relative;
    }

    .error {
      padding-left: 5px;
      font-size: 14px;
      color: $red;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
