.dialog {
  max-width: 700px;
}

.SelectTimeZoneModal {
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 35px 35px;

    p {
      width: 350px;
      margin-bottom: 28px;
      align-self: flex-start;
      font-size: 22px;
      font-weight: bold;
    }

    .buttons {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      button {
        border: none;
        background-color: transparent;
      }

      .confirmContainer {
        display: flex;
        align-self: flex-end;
      }
    }
  }
}
