@import '../../../assets/styles/variables';

.SummaryReport {
  &.export {
    min-height: 100vh;
  }

  background-color: #f9f9f9;
  padding-bottom: 30px;

  .container {
    padding: 20px 40px;
    border-radius: 64px;

    header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 133%;
        color: $primary;
      }

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 133%;
        text-transform: uppercase;
        color: $primary;
      }
    }

    .reportInfo {
      display: flex;
      justify-content: space-between;
      margin-bottom: 28px;

      .titleContainer {
        position: relative;
        left: -10px;
        display: flex;
        align-items: center;
      }

      .contentTitle,
      .reportDate {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 133%;
        color: $text;
      }

      p {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: $text;
      }

      .completionRate {
        margin-bottom: 0;
      }

      .buttonContainer {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
