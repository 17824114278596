@import '../../../assets/styles/variables';

.BigPopup {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
  }

  .popup {
    position: absolute;
    width: 417px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    border-radius: 10px;

    .confirmButtonsContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;
      width: 100%;
      margin-top: auto;
      padding-left: 35px;
      padding-right: 35px;

      button {
        width: 178px;
        height: 60px;
        font-weight: bold;
        border-radius: 10px;
        border: none;

        &.confirmButton {
          background-color: $red;
          color: white;
        }

        &.cancelButton {
          background-color: transparent;
          border: 1px solid $primary;
        }
      }
    }

    .singleButtonContainer {
      width: 100%;
      margin-top: auto;
      padding-left: 35px;
      padding-right: 35px;

      .singleButton {
        width: 100%;
        height: 60px;
        color: white;
        font-weight: bold;
        background-color: $red;
        border-radius: 10px;
        border: none;
      }
    }

    .closeIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    span {
      display: block;
      max-width: 300px;
    }

    p {
      display: flex;
      justify-content: center;
      width: 300px;
      margin: 0;
      font-weight: normal;
      text-align: center;
    }
  }
}
