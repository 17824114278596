.FolderRow {
  .imgContainer {
    .innerContainer {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  img,
  .name {
    position: relative;
    left: -7px;
  }

  &.isSubfolder {
    cursor: pointer;

    &.isExpanded {
      .chevron {
        top: 40%;
        transform: rotate(180deg);
      }
    }

    .chevron {
      position: absolute;
      top: 55%;
      left: -15px;
      transform: translateY(-50%);
      width: 10px;
    }
  }
}
