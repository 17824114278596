@import '../../../assets/styles/variables';

.CreateFolderMenu {
  max-width: 524px;
  min-width: 285px;
  padding: 40px;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 1;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #ffffff;

  .optionsList {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        button {
          width: 100%;
          display: flex;
          align-items: center;
          border: none;
          background-color: transparent;

          img {
            margin-right: 8px;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .departmentFolder {
    label {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .assignFolder {
        display: flex;
        gap: 8px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .assignFolderContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
  }

  .subfolder {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .nameContainer {
      margin-bottom: 8px;
    }
  }

  .buttonContainer {
    margin-top: 12px;
  }

  input {
    padding: 8px 12px;
    border: 1px solid #d4d4d4;
    border-radius: 60px;
    line-height: 100%;
    color: $text;
    outline: none;
  }
}
